import React, { useState } from 'react';
import SEO from '../../components/SEO';

import imageIllustrationMortageFirstSection from '../../assets/images/illustrations/RemortgagePage/variable-mortgage-first.png';
import imageIllustrationMortageFirstSectionMobile from '../../assets/images/illustrations/RemortgagePage/remortgage-first-section-mobile.png';
import imageIllustrationStepFirst from '../../assets/images/illustrations/RemortgagePage/step-image-first.png';
import imageIllustrationStepSecond from '../../assets/images/illustrations/RemortgagePage/step-image-second.png';
import imageIllustrationStepThird from '../../assets/images/illustrations/RemortgagePage/step-image-third.png';
import imageTestimonialsIndi1x from '../../assets/images/illustrations/indi-testimonials1x.png'
import imageTestimonialsIndi2x from '../../assets/images/illustrations/indi-testimonials2x.png'
import imageTestimonialsJenny1x from '../../assets/images/illustrations/jenny-testimonials1x.png'
import imageTestimonialsJenny2x from '../../assets/images/illustrations/jenny-testimonials2x.png'
import imageTestimonialsBrijesh1x from '../../assets/images/illustrations/brijesh-testimonials1x.png'
import imageTestimonialsBrijesh2x from '../../assets/images/illustrations/brijesh-testimonials2x.png'
import imageTestimonialsBella1x from '../../assets/images/illustrations/bella-testimonials1x.png'
import imageTestimonialsBella2x from '../../assets/images/illustrations/bella-testimonials2x.png'


import imageSecondSliderFirst from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderSecond from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderThird from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderFourth from "../../assets/images/illustrations/home-save-money-banner.png"

import imageSecondSliderFirstMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderSecondMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderThirdMobile from "../../assets/images/illustrations/home-save-money-banner.png"
import imageSecondSliderFourthMobile from "../../assets/images/illustrations/home-save-money-banner.png"



import CustomHeadingSection from '../../components/CommonSections/CustomHeadingSection';
import SectionWrapper from '../../components/CommonSections/SectionWrapper';
import useWindow from '../../hooks/useWindow';
import StepsSection from '../../components/CommonSections/StepsSection';
import FAQSection from '../../components/FAQSection';
import HeaderLeftContent from '../../components/CommonSections/CustomHeadingSection/HeaderLeftContent';
import { Link } from 'gatsby';
import useSliderHeight from '../../hooks/useSliderHeight';
import CustomImage from '../../components/Image/Image';
import { useMemo } from 'react';
import loadable from '@loadable/component';

const ModalsFlow = loadable(() => import("../../components/Modals/ModalsFlow"))
const MortgageDetailsSection = loadable(() => import("../../components/CommonSections/MortgageDetails"))
const FullSizeSliderSectionMid = loadable(() => import("../../components/CommonSections/FullSizeSliderSectionMid"))
const SliderSection = loadable(() => import("../../components/CommonSections/SliderSection"))
const DownloadAppSection = loadable(() => import("../../components/CommonSections/DownloadAppSection"))
const ContactWidget = loadable(() => import("../../components/ContactWidget"))

const IS_MOBILE = () => (typeof window !== 'undefined' ? window.innerWidth <= 767.98 : 0);
const IS_TABLET = () => (typeof window !== 'undefined' ? window.innerWidth <= 991.98 : 0);





const FAQItems = [
  {
    title: 'How does Sprive help with remortgaging?',
    description: (
      <>
      The Sprive app compares your existing deal against mortgages in the market to show you live personalised deals every day, so you can find the right mortgage deal. At the right time, we’ll connect you to a mortgage expert who will help you secure your new deal. By switching via the app, we’ll seamlessly help you make mortgage overpayments on your new mortgage and be able to automatically take into account any early repayment charges associated with your new mortgage.
      < br/>< br/>
      If you’re not a user of the app, we can still help you. Simply fill in the above form and an expert advisor will be in touch.
      </>
    ),
  },
  {
    title: 'What is a variable rate mortgage?',
    description: (
      <>
      A variable mortgage, also known as a tracker mortgage or a standard variable rate (SVR) mortgage, is a type of home loan in the UK where the interest rate can change over time. The interest rate is typically linked to an external benchmark, such as the Bank of England's base rate or the lender's own SVR.< br/>< br/>
      The difference with tracker mortgages is that they usually follow the Bank of England base rate
      </>
    ),
  },
  {
    title: 'How does a variable rate mortgage work?',
    description: (
      <>
      With a variable mortgage in the UK, the interest rate can fluctuate in response to changes in the bank of England base rate. The rate may adjust monthly or annually, depending on the terms of the mortgage. If the benchmark mortgage rates increases, your mortgage interest rate and monthly payment may go up. Conversely, if the benchmark rate decreases, your mortgage interest rate and monthly payment may decrease.
      </>
    ),
  },
  {
    title: 'What are the advantages of a variable mortgage?',
    description: (
     <>
     One advantage of a variable mortgage is that it often starts with a lower initial interest rate compared to fixed-rate mortgages. This can result in lower monthly repayments, especially during the early years of the mortgage. Additionally, if the benchmark rate decreases, borrowers with variable rate mortgages in the UK can benefit from potential interest rate reductions and reduced monthly repayments.
     </>
    ),
  },
  {
    title: 'What are the risks of a variable rate mortgage?',
    description: (
      <>
      The main risk of a variable mortgage (or a tracker rate mortgage) is the uncertainty associated with changing interest rates. If the benchmark rate increases, borrowers could experience higher monthly mortgage payments, which can impact their budget. The variability can also make long-term financial planning more challenging.
      </>
    ),
  },
  {
    title: 'How often does the interest rate on a variable rate mortgage adjust in the UK?',
    description: (
      <>
      The frequency of interest rate adjustments for variable rate mortgages in the UK varies depending on the terms of the mortgage and the lender. It is common for adjustments to occur on a monthly or quarterly basis. It's important to review the specific terms of your mortgage agreement to understand how often the interest rate may change.
      </>
    ),
  },
  {
    title: 'Can I switch from a variable rate mortgage to a fixed-rate mortgage in the UK?',
    description: (
     <>
     UK borrowers typically have the option to switch from a variable rate mortgage to a fixed mortgage. This can provide stability and predictable payments if they anticipate interest rates to rise or prefer the certainty of a fixed rate. However, it's important to review any associated fees, potential early repayment charges, or product restrictions before making a decision. Consulting with a mortgage advisor can help you evaluate the options and determine what is suitable for your circumstances.
     </>
    ),
  },
  {
    title: 'How do I know if a variable rate mortgage is right for me?',
    description: (
      <>
      Choosing between a variable rate mortgage and a fixed-rate mortgage in the UK depends on your individual circumstances. Factors such as your budget, interest rate expectations, and the length of time you plan to stay in the property should be considered. Seeking advice from a mortgage professional can help you assess the pros and cons and make an informed decision.
      </>
    ),
  },
  {
    title: 'Can I make overpayments on a variable rate mortgage in the UK?',
    description: (
      <>
      In many cases, UK borrowers with variable rate mortgages are allowed to make overpayments without incurring penalties. However, specific terms and conditions may apply, such as limitations on the amount or frequency of overpayments. Reviewing your mortgage agreement and discussing with your lender will provide clarity on the overpayment options available to you.
      </>
    ),
  },
];

function VariableMorgagePage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [dealsContactModal, setDealsContactModal] = useState(true)
  const { isDesktop, isMobile } = useWindow();
  const [isTablet, setTablet] = useState(false);
  const sliderHeight = useSliderHeight("611px", "198px", "234px")

  const secondSliderItems = useMemo(() => ([
    {
      image: (isMobile || isTablet) ? imageSecondSliderFirstMobile : imageSecondSliderFirst,
      title: "All you need to know about variable rate mortgages?",
      firstDescription: "A variable-rate mortgage is a type of mortgage deal where the interest rate and monthly payments can vary during the mortgage term.",
      secondDescription: (
        <>
        With variable-rate mortgages, your monthly payments can go up or down as the interest rate changes in the Bank of England's base rate or the lender's own standard variable rate.
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderSecondMobile : imageSecondSliderSecond,
      title: "What are the pros and cons of a variable-rate mortgage?",
      secondDescription: (
        <>
          <b>Pros:</b><br /><br />
          If interest rates fall, then your monthly repayments will decrease which should save you money.<br /><br />
          Variable mortgages can often come with a lower initial interest rate than fixed mortgages.<br /><br />
          Most variable-rate deals allow you to make overpayments.
          <b>Cons:</b> <br /><br />
          If interest rates rise, it will lead to higher monthly repayments.<br /><br />
          Variable mortgages lack the stability and predictability of fixed-rate mortgages.<br /><br />
          With the fluctuations in your mortgage payments, it’s harder for you to budget.
        </>
      )
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderThirdMobile : imageSecondSliderThird,
      title:"When is a good time to get a variable-rate mortgage?",
      firstDescription: "If that interest rates are at a relatively low point or are likely to decrease in the future, a variable mortgage could be a good option as it could offer savings.",
      secondDescription: "Remember, predicting interest movements is challenging, and it's essential to carefully evaluate your financial situation and risk tolerance.",
    },
    {
      image: (isMobile || isTablet) ? imageSecondSliderFourthMobile : imageSecondSliderFourth,
      title: "What is a Standard Variable Rate Mortgage(SVR)?",
      firstDescription: "A Standard Variable Rate (SVR) is the mortgage interest rate is set by the lender. It is the default rate that you will move to after their initial fixed or discounted rate period ends, if you don’t remortgage to another deal.",
      secondDescription: (
        <>
        Whilst being on the lender's SVR offers flexibility as they typically do not have an early repayment charge or exit fees, SVRs tend to be higher than the initial fixed or discounted rates offered by lenders.<br /><br />
        A lender's standard variable rate can increase or decrease independently of changes in the wider market as it’s up to the lender’s discretion to set. It's important to review the lender's SVR when the initial rate period ends.
        </>
      )
    },
  ]), [isMobile, isTablet]);


  return (
    <>
      <SEO title="Remortgage Page" />
      <div className="common-page remortgage-page">
        <div className="remortgage-page__heading-container">
          <SectionWrapper isFirstSection={true}>
            <CustomHeadingSection
              leftContent={
                <HeaderLeftContent
                  title={
                    <>
                      What is a variable rate mortgage?
                      {isDesktop ? <br /> : ' '}
                      mortgage?
                    </>
                  }
                  image={isMobile ? imageIllustrationMortageFirstSectionMobile : null}
                  imageReversed
                  description="In just a few clicks, we'll help you find a personalised variable mortgage deal across the market."
                  buttonText="Compare Deals"
                  hashUrlOnClick="/mortgages/remortgage#mortgage-details"
                  useWidget
                />
              }
            />
            {!isMobile && (
              <div className="main-image-container">
                <CustomImage
                    className="w-100 main-image"
                    src={imageIllustrationMortageFirstSection}
                    alt="main image"
                  />
              </div>
            )}
          </SectionWrapper>
        </div>
        <div style={{minWidth: "100vw", minHeight: isMobile ? "826px" : "1000px"}}>
          <FullSizeSliderSectionMid
            sliderItems={secondSliderItems}
          />
        </div>
        <FAQSection
          FAQItems={FAQItems}
          title={
            <>
              Question about variable-rate mortgages?
              {isDesktop ? <br /> : ' '}
              Check out our FAQ’s
            </>
          }
        />
        <div style={{minHeight: isMobile ? "382px" : "429px"}}>
          <DownloadAppSection />
        </div>
        <ContactWidget
          handleClick={() => {
            setIsContactModalOpen(true)
            setDealsContactModal(false)
          }}
          title="Speak to a mortgage expert for FREE"
        />
        <ModalsFlow
          isFirstOpened={isContactModalOpen}
          setIsFirstOpened={(state) => setIsContactModalOpen(state)}
          dealsContactModal={dealsContactModal}
        />
      </div>
    </>
  );
}



export default VariableMorgagePage;
